(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.factory:OrderHistory
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .factory('OrderHistory', OrderHistory);

  function OrderHistory(_) {
    var defaultSize = 15;

    function OrderHistoryBase(Order) {
      this.Order = Order;
      this.orders = [];
      this.size = defaultSize;
      this.filter = {};
      this.currentPage = 1;
      this.isBusy = false;
    }

    OrderHistoryBase.prototype.refreshOrders = function refreshOrders() {
      var self = this;
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;
      this.Order.query(getQueryParams()).$promise
        .then(function (orders) {
          setOrders(orders);
          updatePagesAvailable(orders);
        })
        .finally(function () {
          self.isBusy = false;
        });

      function getQueryParams() {
        var queryParams = {
          page: self.currentPage,
          size: self.size + 1
        };

        return angular.extend(queryParams, self.filter);
      }

      function setOrders(orders) {
        self.orders = _.take(orders, self.size);
      }

      function updatePagesAvailable(orders) {
        self.isPrevPageAvailable = self.currentPage > 1;
        self.isNextPageAvailable = orders.length > self.size;
      }
    };

    OrderHistoryBase.prototype.isLoading = function isLoading() {
      return this.isBusy;
    };

    OrderHistoryBase.prototype.getOrders = function getOrders() {
      return this.orders;
    };

    OrderHistoryBase.prototype.getPage = function getPage() {
      return this.currentPage;
    };

    OrderHistoryBase.prototype.getSize = function getSize() {
      return this.size;
    };

    OrderHistoryBase.prototype.nextPage = function nextPage() {
      if (!this.hasNextPage()) {
        return;
      }
      this.currentPage++;
    };

    OrderHistoryBase.prototype.previousPage = function previousPage() {
      if (!this.hasPrevoiusPage()) {
        return;
      }
      this.currentPage--;
    };

    OrderHistoryBase.prototype.setSize = function setSize(size) {
      this.size = parseInt(size, 10) || defaultSize;
    };
    OrderHistoryBase.prototype.setFilter = function setFilter(filter) {
      this.filter = filter;
    };

    OrderHistoryBase.prototype.hasPrevoiusPage = function hasPrevoiusPage() {
      return this.isPrevPageAvailable;
    };

    OrderHistoryBase.prototype.hasNextPage = function hasNextPage() {
      return this.isNextPageAvailable;
    };

    return OrderHistoryBase;
  }
}());
